.vehicle {
  margin: $spacing-24 $spacing-12;
  padding: $spacing-12 $spacing-24 $spacing-24;
  border-radius: $radius-lg;

  h2 {
    color: #ffffff;
    display: flex;
    padding: $spacing-8 0 $spacing-16;
    justify-content: center;
    align-items: center;
    gap: $spacing-16;
  }

  &-a {
    background: $color-corporate-primary;
    box-shadow: 5px 20px 35px 0 rgba(16, 88, 117, 0.5);

    .vehicle-field-check {
      .input__field:checked + .input__checkbox-indicator {
        background-color: $color-corporate-primary;
      }
    }
  }

  &-b {
    background: $color-corporate-secondary;
    box-shadow: 5px 20px 35px 0 rgba(248, 177, 51, 0.5);

    .vehicle-field-check {
      .input__field:checked + .input__checkbox-indicator {
        background-color: $color-corporate-secondary;
      }
    }
  }

  &-field-check {
    background: #ffffff;
    display: flex;
    padding: $spacing-8 $spacing-6;
    margin-bottom: $spacing-8;
    border-radius: $radius-2xl;
    .input__checkbox {
      align-items: flex-start;
      gap: $spacing-8;
      .input__checkbox-indicator {
        min-width: 24px;
        min-height: 24px;
      }
      .input__label {
        @include font-mobile-courant-bold;
        line-height: 24px;

        .placeholder {
          opacity: 0.5;
        }
      }
    }
  }

  .btn {
    margin: $spacing-24 auto 0;
  }
}
