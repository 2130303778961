.input {
  position: relative;
  &__checkbox,
  &__toggle {
    display: flex;
    align-items: center;
    gap: $spacing-12;
    cursor: pointer;

    &:read-only {
      cursor: default;
      .input__label {
        cursor: default;
      }
    }

    &-indicator {
      min-width: 28px;
      min-height: 28px;
      border-radius: $radius-full;
      background-color: $color-complement-clear-blue;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease-in-out;
    }

    .input__field {
      appearance: none;
      display: none;

      &:checked + .input__checkbox-indicator {
        background-color: $color-corporate-green;
      }
    }

    .input__label {
      @include font-mobile-small-text;
      color: $color-corporate-tertiary;

      a {
        color: inherit;
        text-decoration: underline;
        transition: color 0.3s ease-in-out;

        &:hover {
          color: $color-corporate-primary;
        }
      }
    }
  }
  &__toggle {
    gap: $spacing-20;
    justify-content: center;

    &-widget {
      height: 25px;
    }
  }

  &:not(:has(&__checkbox, &__toggle)) &__field {
    border: 0;
    color: $color-corporate-tertiary;
    background-color: $color-complement-clear-blue;
    padding: $spacing-26 $spacing-16 $spacing-6 $spacing-16;
    border-radius: $radius-lg;
    width: 100%;
    @include font-mobile-courant-regular;

    position: relative;
    z-index: 1;

    &::placeholder {
      color: $color-corporate-tertiary;
    }

    &__google_places {
      &::placeholder {
        color: transparent;
      }
    }

    &:is(:focus, :hover) {
      outline: none;
      background-color: darken($color-complement-clear-blue, 5%);
    }

    & + .input__label {
      @include font-mobile-courant-regular;
      position: absolute;
      z-index: 2;
      left: $spacing-16;
      bottom: $spacing-16;
      color: $color-corporate-tertiary;
      opacity: 0.5;
      transition: 0.3s;
      cursor: default;
    }

    &:focus,
    &_filled {
      + .input__label {
        @include font-mobile-small-text;
        opacity: 1;
        color: $color-corporate-primary;
        bottom: $spacing-32;
      }
    }

    &_dirty:invalid,
    &_error {
      border: 1px solid $color-complement-error;
      + .input__label {
        color: $color-complement-error;
      }
    }
  }

  &__label {
    cursor: pointer;
    user-select: none;
  }

  &__textarea {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;

    .input__label {
      padding-left: $spacing-16;
      color: $color-corporate-tertiary;
      @include font-mobile-courant-bold;
    }

    textarea {
      resize: none;
      color: $color-corporate-tertiary;
      @include font-mobile-courant-regular;
    }
  }
}
