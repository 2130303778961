.screen.start {
  .illustration {
    display: flex;
    justify-content: center;

    &:has(.illustration__loader) {
      height: 100%;
    }
  }

  .illustration_start {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .registration {
    @include font-mobile-titre-bold;
    display: flex;
    height: 46px;
    padding: $spacing-10 0;
    margin: $spacing-36 $spacing-12;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: $radius-xl;
    border: 1px dashed $color-corporate-tertiary;
  }
}
