.footer {
  background-color: $color-complement-clear-blue;
  border-top-left-radius: $radius-xl;
  border-top-right-radius: $radius-xl;
  padding: $spacing-10 $spacing-10 $spacing-40 $spacing-10;
  text-align: center;
  margin-top: auto;

  &__content {
    display: flex;
    flex-direction: column;

    :is(.footer__website, .footer__phone) {
      text-decoration: none;
    }
  }

  &__website {
    color: black;
  }

  &__title {
    @include font-mobile-titre-bold;
  }

  &___text {
  }

  &__phone {
    color: $color-corporate-primary;
    @include font-mobile-titre-bold;
  }
}
