.vehicle-damage-selector {
  position: relative;

  .vehicle-damage-selector-shape {
    display: flex;
    justify-content: center;
    margin: $spacing-64 0;
  }

  .vehicle-letter {
    @include font-mobile-h1;
    position: absolute;
    left: calc(50% - 10px);
  }

  .vehicle-impact {
    position: absolute;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: $radius-full;
    background: $color-corporate-green;
    color: #ffffff;
    @include font-mobile-titre-bold;
    cursor: pointer;

    &-selected {
      background: none;
      img {
        width: 96px;
        height: 96px;
        margin-top: -28px;
      }
    }
  }

  &.vehicle-damage-selector- {
    &vehicle-a {
      > .vehicle-damage-selector-shape {
        color: $color-corporate-primary;
      }
      > .vehicle-letter {
        color: #ffffff;
      }
    }
    &vehicle-b {
      > .vehicle-damage-selector-shape {
        color: $color-corporate-secondary;
      }
      > .vehicle-letter {
        color: $color-corporate-tertiary;
      }
    }
  }

  &.vehicle-damage-selector-shape- {
    // AUTO
    &AUTO {
      .vehicle-letter {
        top: 188px;
      }
      .vehicle-impact- {
        &FRONT_MIDDLE {
          left: calc(50% - 20px);
          top: 16px;
        }
        &BACK_MIDDLE {
          left: calc(50% - 20px);
          top: 330px;
        }
        &LEFT_MIDDLE {
          left: calc(50% - 100px);
          top: 175px;
        }
        &RIGHT_MIDDLE {
          left: calc(50% + 60px);
          top: 175px;
        }
        &FRONT_LEFT {
          left: calc(50% - 100px);
          top: 50px;
        }
        &FRONT_RIGHT {
          left: calc(50% + 60px);
          top: 50px;
        }
        &BACK_LEFT {
          left: calc(50% - 100px);
          top: 300px;
        }
        &BACK_RIGHT {
          left: calc(50% + 60px);
          top: 300px;
        }
      }
    }

    // TRUCK
    &TRUCK {
      .vehicle-letter {
        top: 188px;
      }
      .vehicle-impact- {
        &FRONT_MIDDLE {
          left: calc(50% - 20px);
          top: 16px;
        }
        &BACK_MIDDLE {
          left: calc(50% - 20px);
          top: 330px;
        }
        &LEFT_MIDDLE {
          left: calc(50% - 100px);
          top: 175px;
        }
        &RIGHT_MIDDLE {
          left: calc(50% + 60px);
          top: 175px;
        }
        &FRONT_LEFT {
          left: calc(50% - 100px);
          top: 50px;
        }
        &FRONT_RIGHT {
          left: calc(50% + 60px);
          top: 50px;
        }
        &BACK_LEFT {
          left: calc(50% - 100px);
          top: 300px;
        }
        &BACK_RIGHT {
          left: calc(50% + 60px);
          top: 300px;
        }
      }
    }

    // VUL
    &VUL {
      .vehicle-letter {
        top: 188px;
      }
      .vehicle-impact- {
        &FRONT_MIDDLE {
          left: calc(50% - 20px);
          top: 16px;
        }
        &BACK_MIDDLE {
          left: calc(50% - 20px);
          top: 330px;
        }
        &LEFT_MIDDLE {
          left: calc(50% - 100px);
          top: 175px;
        }
        &RIGHT_MIDDLE {
          left: calc(50% + 60px);
          top: 175px;
        }
        &FRONT_LEFT {
          left: calc(50% - 100px);
          top: 50px;
        }
        &FRONT_RIGHT {
          left: calc(50% + 60px);
          top: 50px;
        }
        &BACK_LEFT {
          left: calc(50% - 100px);
          top: 300px;
        }
        &BACK_RIGHT {
          left: calc(50% + 60px);
          top: 300px;
        }
      }
    }

    // MOTO
    &MOTO {
      .vehicle-letter {
        top: 188px;
      }
      .vehicle-impact- {
        &FRONT_MIDDLE {
          left: calc(50% - 20px);
          top: 16px;
        }
        &BACK_MIDDLE {
          left: calc(50% - 20px);
          top: 330px;
        }
        &LEFT_MIDDLE {
          left: calc(50% - 100px);
          top: 175px;
        }
        &RIGHT_MIDDLE {
          left: calc(50% + 60px);
          top: 175px;
        }
      }
    }

    // BIKE
    &BIKE {
      .vehicle-letter {
        top: 188px;
      }
      .vehicle-impact- {
        &FRONT_MIDDLE {
          left: calc(50% - 20px);
          top: 16px;
        }
        &BACK_MIDDLE {
          left: calc(50% - 20px);
          top: 330px;
        }
        &LEFT_MIDDLE {
          left: calc(50% - 100px);
          top: 175px;
        }
        &RIGHT_MIDDLE {
          left: calc(50% + 60px);
          top: 175px;
        }
      }
    }

    // SCOOTER
    &SCOOTER {
      .vehicle-letter {
        top: 188px;
      }
      .vehicle-impact- {
        &FRONT_MIDDLE {
          left: calc(50% - 20px);
          top: 16px;
        }
        &BACK_MIDDLE {
          left: calc(50% - 20px);
          top: 330px;
        }
        &LEFT_MIDDLE {
          left: calc(50% - 100px);
          top: 175px;
        }
        &RIGHT_MIDDLE {
          left: calc(50% + 60px);
          top: 175px;
        }
      }
    }
  }
}
