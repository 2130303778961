.header {
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 10;

  &__brand {
    //padding: $spacing-50 $spacing-10 $spacing-10 $spacing-10;
    padding: $spacing-10;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    gap: $spacing-26;

    &-text {
      @include font-mobile-titre-bold;
      color: $color-corporate-primary;
      text-align: center;

      strong {
        color: $color-corporate-secondary;
      }
    }

    &-logo {
      //max-width: 250px;
      width: 100%;
    }

    .environment-indicator {
      @include font-mobile-caption;
      background: $color-corporate-black;
      color: #ffffff;
      padding: $spacing-2 $spacing-4;
      border-radius: $radius-sm;
      position: absolute;
      top: $spacing-8;
      right: $spacing-8;
      cursor: default;
    }
  }

  &--minimized &__brand {
    &-logo {
      max-width: 177px;
      max-height: 51px;
    }
  }
}
