.screen.step12-constat-preview {
  .screen__content {
    .content {
      justify-content: center;

      .react-pdf__Document {
        .react-pdf__message {
          display: flex;
          gap: $spacing-16;
          flex-direction: column;
          align-items: center;
        }
      }

      .illustration__loader {
        width: 58px;
        height: 58px;
        padding: 0;

        + .react-pdf__Document {
          display: none;
        }
      }
    }
  }
}
