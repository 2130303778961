.screen.step9-chatbot {
  .screen__content {
    .content {
      .chatbot {
        display: flex;
        flex: 1;

        .chatbot-error {
          display: flex;
          gap: $spacing-16;
          flex-direction: column;
          align-items: center;
          flex: 1;
        }

        &:has(.ziiircom-messenger-frame) {
          .illustration__loader {
            display: none;
          }
        }

        .ziiircom-messenger-frame {
          display: flex;
          flex: 1;
          .ziiircom-messenger {
            position: relative;
            max-height: unset !important;
            height: 100% !important;
            bottom: unset !important;
            right: unset !important;
            width: 100% !important;

            .ziiir-messenger-inner {
              max-width: 100%;
            }

            .ziiircom-messenger-popin,
            .ziiircom-messenger-about,
            header {
              display: none;
            }

            .ziiir-message-avatar {
              margin-top: 0;
            }

            .ziiir-quick-replies {
              margin-top: 8px;
              gap: 24px;
            }
            .ziiir-quick-replies button.ziiir-quickreply {
              margin: 0;
              padding: 8px 24px;
            }
            .ziiir-quick-replies button.ziiir-quickreply.with-icon {
              display: flex;
              place-items: center;
              place-content: center;
              gap: 8px;
            }
            .ziiir-quick-replies button.ziiir-quickreply.full-width {
              width: 90%;
            }
            .ziiir-quick-replies button.ziiir-quickreply.outline {
              background: none;
              color: #114864;
            }
            .ziiir-quick-replies button.ziiir-quickreply.secondary {
              background-color: #b5cbd5;
              border-color: #b5cbd5;
              color: #114864;
            }
            .ziiir-input {
              color: rgb(8, 11, 26) !important;
            }
            .ziiir-conversation {
              width: 100%;
              overflow-x: hidden;
            }
            .ziiir-message .center {
              max-width: 90%;
              text-align: center;
              margin: 16px auto;
              display: block;
            }

            .ziiir-message-carousel .ziiir-message-carousel-inner {
              display: flex;
              align-items: flex-start;
              gap: $spacing-12;
            }

            .ziiir-message-container {
              margin-top: $spacing-16;
            }

            .ziiir-message-container .ziiir-message .ziiir-message-content,
            .ziiir-message-container + .ziiir-message-carousel .ziiir-card {
              border-radius: $radius-2xl;
              background: $color-complement-clear-blue;
              display: flex;
              padding: $spacing-16;
              flex-direction: column;
              align-items: flex-start;
              gap: 16px;
              @include font-mobile-courant-regular;
            }

            .ziiir-message-container + .ziiir-message-carousel .ziiir-card {
              align-self: stretch;

              .ziiir-card-button {
                display: flex;
                width: 100%;
                padding: $spacing-12 $spacing-20;
                justify-content: center;
                align-items: center;
                gap: $spacing-10;
                align-self: stretch;

                border-radius: $radius-full;
                border: none;
                background: $color-corporate-green;
                color: #ffffff;

                @include font-mobile-button;
              }
            }

            .ziiir-message-container.personA
              .ziiir-message
              .ziiir-message-content,
            .ziiir-message-container.personA
              + .ziiir-message-carousel
              .ziiir-card {
              background: #f1f8fd;
              border-color: #f1f8fd;
            }
            .ziiir-message-container.personB
              .ziiir-message
              .ziiir-message-content,
            .ziiir-message-container.personB
              + .ziiir-message-carousel
              .ziiir-card {
              background: #fefdea;
              border-color: #fefdea;
            }
            .ziiir-message-container .personA,
            .ziiir-message-container .personB {
              padding: 4px 6px;
              border-radius: 4px;
              line-height: 2em;
              white-space: nowrap;
            }
            .ziiir-message-container
              + .ziiir-message-container[fromuser='true']
              .ziiir-message
              .ziiir-message-content {
              border-radius: $radius-2xl;
              background: $color-corporate-primary;
              color: #ffffff;
            }
            .ziiir-message-container.personA
              + .ziiir-message-container[fromuser='true']
              .ziiir-message
              .ziiir-message-content,
            .ziiir-carousel.personA
              + .ziiir-message-container[fromuser='true']
              .ziiir-message
              .ziiir-message-content,
            .ziiir-message-container .personA {
              background: #b8ddf6;
              color: $color-corporate-primary;
            }
            .ziiir-message-container.personB
              + .ziiir-message-container[fromuser='true']
              .ziiir-message
              .ziiir-message-content,
            .ziiir-carousel.personB
              + .ziiir-message-container[fromuser='true']
              .ziiir-message
              .ziiir-message-content,
            .ziiir-message-container .personB {
              background: #fefab9;
              color: $color-corporate-primary;
            }
            .ziiir-quick-replies button.ziiir-quickreply .personA,
            .ziiir-quick-replies button.ziiir-quickreply .personB {
              color: rgb(17, 72, 100);
            }
            .ziiir-message-container.personA
              .ziiir-quick-replies
              .ziiir-quickreply,
            .ziiir-carousel.personA .ziiir-quick-replies .ziiir-quickreply,
            .ziiir-file-input.personA .ziiir-message-file-input label,
            .ziiir-message-container.personA
              + .ziiir-message-carousel
              .ziiir-card
              .ziiir-card-button {
              background: #b8ddf6;
              border-color: #b8ddf6;
              color: rgb(17, 72, 100);
            }
            .ziiir-message-container.personB
              .ziiir-quick-replies
              .ziiir-quickreply,
            .ziiir-carousel.personB .ziiir-quick-replies .ziiir-quickreply,
            .ziiir-file-input.personB .ziiir-message-file-input label,
            .ziiir-message-container.personB
              + .ziiir-message-carousel
              .ziiir-card
              .ziiir-card-button {
              background: #fefab9;
              border-color: #fefab9;
              color: rgb(17, 72, 100);
            }
          }
          .ziiircom-messenger-fab {
            display: none;
          }
        }
      }
    }
  }
}
