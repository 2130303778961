@import 'assets/scss/skin';

.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  section {
    > img {
      width: 100%;
    }
  }
}
