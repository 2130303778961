.screen.step5-initial-impact-a,
.screen.step6-damages-a,
.screen.step7-initial-impact-b,
.screen.step8-damages-b {
  .screen__content {
    .content {
      .vehicle {
        padding-top: 0;
        padding-bottom: 0;
        h2 {
          padding-top: 0;
          padding-bottom: 0;

          img {
            max-height: 64px;
            max-width: 64px;
          }
          svg {
            margin: 9px 0;
            max-height: 42px;
          }
        }
      }
    }
  }
}
