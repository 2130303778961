.notification {
  border-radius: $radius-sm;
  padding: $spacing-6;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  line-height: 1;
  font-size: 13px;

  $variants: (
    primary: (
      'background-color': $color-corporate-primary,
      'color': white,
    ),
    secondary: (
      'background-color': $color-corporate-secondary,
      'color': white,
    ),
    tertiary: (
      'background-color': $color-corporate-tertiary,
      'color': white,
    ),
  );

  @each $variant, $properties in $variants {
    &--#{$variant} {
      background-color: map-get($properties, 'background-color');
      color: map-get($properties, 'color');
    }
  }
}
