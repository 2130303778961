.list {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
  margin: $spacing-10 $spacing-12 0 $spacing-12;

  &--ordered {
    list-style-type: none;
    counter-reset: counter;

    & .list__item {
      counter-increment: counter;
      display: flex;
      gap: $spacing-12;

      &::before {
        @include font-mobile-timeline;
        content: counter(counter);
        display: flex;
        justify-content: center;
        min-width: 28px;
        height: 28px;
        align-items: center;
        padding: $spacing-2;
        border-radius: $radius-full;
        background-color: $color-corporate-primary;
        color: white;
      }
    }
  }
}
