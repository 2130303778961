.actions {
  display: flex;
  box-shadow: 0px -8px 50px 50px #07217a1f;
  background-color: white;
  //padding: $spacing-12 $spacing-12 $spacing-36 $spacing-12;
  padding: $spacing-12;
  margin-top: auto;
  gap: $spacing-12;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 10;
}
