.screen {
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'content';

  &:has(.screen__title) {
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    grid-template-areas:
      'title'
      'content';

    .screen__content {
      border-top-left-radius: $radius-xl;
      border-top-right-radius: $radius-xl;
    }
  }

  &__content {
    grid-area: content;
    background-color: white;
    display: flex;
    flex-direction: column;

    .content {
      h3 {
        @include font-mobile-courant-bold;
        text-align: center;
        margin-top: $spacing-12;
      }
    }
  }

  &__title {
    grid-area: title;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacing-28 $spacing-12;
    text-align: center;

    h1 {
      margin: 0;
      color: $color-corporate-primary;
    }
  }

  &.screen-step {
    .screen__title {
      h1 {
        @include font-mobile-courant-regular;
        strong {
          @include font-mobile-courant-bold;
        }
      }
    }
  }
}
