.text {
  $align: (
    left: left,
    center: center,
    right: right,
    justify: justify,
  );

  @each $key, $value in $align {
    &--#{$key} {
      text-align: $value;
    }
  }

  $weight: (
    regular: 400,
    bold: 600,
  );

  @each $key, $value in $weight {
    &--#{$key} {
      font-weight: $value;
    }
  }
}
