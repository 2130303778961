.content {
  .illustration {
    margin-inline: $spacing-12;
  }
}

.illustration {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;

  &:has(:nth-child(2)) {
    gap: $spacing-12;
  }

  &--bordered {
    border-bottom: 1px solid $color-complement-subtil;
  }

  &__image {
    margin-block: auto;
    width: 100%;
    height: auto;
  }

  &__title {
    display: flex;
    align-items: center;
    color: $color-corporate-primary;
    @include font-mobile-titre-bold;
  }

  &__text {
    @include font-mobile-small-text;
    display: flex;
    align-items: center;
  }

  &__loader {
    margin: 0 auto;
    width: $loader-default-size;
  }
}
