.screen.step4-photos {
  .screen__content {
    .content {
      gap: $spacing-24;
      .instructions {
        text-align: center;
      }
    }
  }
}
