.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $spacing-16;

  &__group {
    display: flex;
    gap: $spacing-16;
    flex-wrap: wrap;
  }

  .input {
    flex: 1 1 0;
  }
}
