// region Breakpoints

$breakpoint-mobile: 576px;
$breakpoint-tablette: 768px;
$breakpoint-desktop: 1200px;

// endregion

// region Spacing

$spacing-2: 2px;
$spacing-4: 4px;
$spacing-6: 6px;
$spacing-8: 8px;
$spacing-10: 10px;
$spacing-12: 12px;
$spacing-15: 15px;
$spacing-16: 16px;
$spacing-18: 18px;
$spacing-20: 20px;
$spacing-24: 24px;
$spacing-26: 26px;
$spacing-28: 28px;
$spacing-32: 32px;
$spacing-36: 36px;
$spacing-40: 40px;
$spacing-48: 48px;
$spacing-50: 50px;
$spacing-64: 64px;
$spacing-80: 80px;

// endregion

// region Fonts

// region Desktop
// H1
$desktop-font-family-h1: 'Open sans';
$desktop-font-size-h1: 56px;
$desktop-line-height-h1: 85px;
$desktop-font-weight-h1: 600;
// H2
$desktop-font-family-h2: 'Open sans';
$desktop-font-size-h2: 48px;
$desktop-line-height-h2: 110px;
$desktop-font-weight-h2: 600;
// Titre Bold
$desktop-font-family-titre-bold: 'Open sans';
$desktop-font-size-titre-bold: 32px;
$desktop-font-weight-titre-bold: 600;
// Titre Regular
$desktop-font-family-titre-regular: 'Open sans';
$desktop-font-size-titre-regular: 24px;
$desktop-font-weight-titre-regular: 400;
// Courant Bold
$desktop-font-family-courant-bold: 'Open sans';
$desktop-font-size-courant-bold: 18px;
$desktop-line-height-courant-bold: 140%;
$desktop-font-weight-courant-bold: 600;
// Courant Regular
$desktop-font-family-courant-regular: 'Open sans';
$desktop-font-size-courant-regular: 18px;
$desktop-line-height-courant-regular: 140%;
$desktop-font-weight-courant-regular: 400;
// Small Text
$desktop-font-family-small-text: 'Open sans';
$desktop-font-size-small-text: 16px;
$desktop-line-height-small-text: 140%;
$desktop-font-weight-small-text: 400;
// Caption
$desktop-font-family-caption: 'Open sans';
$desktop-font-size-caption: 14px;
$desktop-line-height-caption: 140%;
$desktop-font-weight-caption: 400;
// Button
$desktop-font-family-button: 'Open sans';
$desktop-font-size-button: 20px;
$desktop-font-weight-button: 600;
// Link
$desktop-font-family-link: 'Open sans';
$desktop-font-size-link: 18px;
$desktop-font-weight-link: 600;
// endregion

// region Mobile
// H1
$mobile-font-family-h1: 'Open sans';
$mobile-font-size-h1: 31px;
$mobile-line-height-h1: 140%;
$mobile-font-weight-h1: 700;
// H2
$mobile-font-family-h2: 'Open sans';
$mobile-font-size-h2: 25px;
$mobile-line-height-h2: 140px;
$mobile-font-weight-h2: 600;
// Titre Bold
$mobile-font-family-titre-bold: 'Open sans';
$mobile-font-size-titre-bold: 20px;
$mobile-font-weight-titre-bold: 700;
$mobile-letter-spacing-titre-bold: -0.4px;
// Titre Regular
$mobile-font-family-titre-regular: 'Open sans';
$mobile-font-size-titre-regular: 20px;
$mobile-font-weight-titre-regular: 400;
// Courant Bold
$mobile-font-family-courant-bold: 'Open sans';
$mobile-font-size-courant-bold: 16px;
$mobile-line-height-courant-bold: 140%;
$mobile-font-weight-courant-bold: 700;
// Courant Regular
$mobile-font-family-courant-regular: 'Open sans';
$mobile-font-size-courant-regular: 16px;
$mobile-line-height-courant-regular: 140%;
$mobile-font-weight-courant-regular: 400;
// Small Text
$mobile-font-family-small-text: 'Open sans';
$mobile-font-size-small-text: 13px;
$mobile-line-height-small-text: 140%;
$mobile-font-weight-small-text: 400;
// Caption
$mobile-font-family-caption: 'Open sans';
$mobile-font-size-caption: 10px;
$mobile-line-height-caption: 140%;
$mobile-font-weight-caption: 400;
// Button
$mobile-font-family-button: 'Open sans';
$mobile-font-size-button: 16px;
$mobile-font-weight-button: 600;
// Link
$mobile-font-family-link: 'Open sans';
$mobile-font-size-link: 16px;
$mobile-font-weight-link: 600;
// Timeline
$mobile-font-family-timeline: 'Open sans';
$mobile-font-size-timeline: 13px;
$mobile-font-weight-timeline: 700;
// IconLegend
$mobile-font-family-icon-legend: 'Open sans';
$mobile-font-size-icon-legend: 14px;
$mobile-font-weight-icon-legend: 700;
// endregion

// endregion

// region Colors
$color-corporate-primary: #105875;
$color-corporate-secondary: #f8b133;
$color-corporate-tertiary: #0f1223;
$color-corporate-green: #3ab54a;
$color-corporate-black: #24243f;

$color-complement-primary: #0f1223;
$color-complement-subtil: #8c8ca1;
$color-complement-form: #e4ecef;
$color-complement-clear-blue: #e5f6f8;
$color-complement-blue: #7fc5d2;
$color-complement-error: #ed4b68;
$color-complement-valid: #31d0aa;
$color-complement-warning: #ffb802;
$color-complement-contour-on: #3ab54a;
$color-complement-contour-off: #8c8ca1;
// endregion

// region Radius
$radius-xs: 4px;
$radius-sm: 8px;
$radius-md: 12px;
$radius-lg: 16px;
$radius-xl: 20px;
$radius-2xl: 24px;
$radius-3xl: 28px;
$radius-4xl: 32px;
$radius-full: 999px;
// endregion

// region Miscellaneous
$timeline-step-thumb-size: 26px;
$loader-default-size: 48px;
// endregion
