.content {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  overflow-y: auto;
  height: 100%;

  & > *:not(.vehicle) {
    padding-inline: $spacing-12;

    &:first-child {
      padding-top: $spacing-12;
    }

    &:last-child {
      padding-bottom: $spacing-12;
    }
  }
}
