.timeline-step {
  width: 100%;
  display: flex;
  margin-top: $spacing-16;

  .track-line,
  .track-fill {
    margin: $spacing-12;
    width: 100%;
    height: 4px;
    border-radius: 100px;
    background: #ffffff;
  }
  .track-fill {
    position: absolute;
    background: $color-corporate-green;
  }
  .thumb {
    position: absolute;
    background: $color-corporate-green;
    @include font-mobile-timeline;
    color: #ffffff;
    border-radius: 50%;
    margin: 1px;
    width: $timeline-step-thumb-size;
    height: $timeline-step-thumb-size;
    display: flex;
    justify-content: center;
    text-align: center;
    line-height: 190%;
  }

  @for $step from 1 through 12 {
    &.timeline-step-#{$step} {
      .track-fill {
        width: calc(100% / 13 * #{$step} - $timeline-step-thumb-size / 2);
      }
      .thumb {
        margin-left: calc(100% / 13 * #{$step} - $timeline-step-thumb-size);
      }
    }
  }
}
