// Desktop
@mixin font-desktop-h1 {
  font-family: $desktop-font-family-h1;
  font-weight: $desktop-font-weight-h1;
  font-style: normal;
  font-size: $desktop-font-size-h1;
  line-height: $desktop-line-height-h1;
}

@mixin font-desktop-h2 {
  font-family: $desktop-font-family-h2;
  font-weight: $desktop-font-weight-h2;
  font-style: normal;
  font-size: $desktop-font-size-h2;
  line-height: $desktop-line-height-h2;
}

@mixin font-desktop-titre-bold {
  font-family: $desktop-font-family-titre-bold;
  font-weight: $desktop-font-weight-titre-bold;
  font-style: normal;
  font-size: $desktop-font-size-titre-bold;
}

@mixin font-desktop-titre-regular {
  font-family: $desktop-font-family-titre-regular;
  font-weight: $desktop-font-weight-titre-regular;
  font-style: normal;
  font-size: $desktop-font-size-titre-regular;
}

@mixin font-desktop-courant-bold {
  font-family: $desktop-font-family-courant-bold;
  font-weight: $desktop-font-weight-courant-bold;
  font-style: normal;
  font-size: $desktop-font-size-courant-bold;
  line-height: $desktop-line-height-courant-bold;
}

@mixin font-desktop-courant-regular {
  font-family: $desktop-font-family-courant-regular;
  font-weight: $desktop-font-weight-courant-regular;
  font-style: normal;
  font-size: $desktop-font-size-courant-regular;
  line-height: $desktop-line-height-courant-regular;
}

@mixin font-desktop-small-text {
  font-family: $desktop-font-family-small-text;
  font-weight: $desktop-font-weight-small-text;
  font-style: normal;
  font-size: $desktop-font-size-small-text;
  line-height: $desktop-line-height-small-text;
}

@mixin font-desktop-caption {
  font-family: $desktop-font-family-caption;
  font-weight: $desktop-font-weight-caption;
  font-style: normal;
  font-size: $desktop-font-size-caption;
  line-height: $desktop-line-height-caption;
}

@mixin font-desktop-button {
  font-family: $desktop-font-family-button;
  font-weight: $desktop-font-weight-button;
  font-style: normal;
  font-size: $desktop-font-size-button;
}

@mixin font-desktop-link {
  font-family: $desktop-font-family-link;
  font-weight: $desktop-font-weight-link;
  font-style: normal;
  font-size: $desktop-font-size-link;
}

// Mobile
@mixin font-mobile-h1 {
  font-family: $mobile-font-family-h1;
  font-weight: $mobile-font-weight-h1;
  font-style: normal;
  font-size: $mobile-font-size-h1;
  line-height: $mobile-line-height-h1;
  letter-spacing: -0.4px;
}

@mixin font-mobile-h2 {
  font-family: $mobile-font-family-h2;
  font-weight: $mobile-font-weight-h2;
  font-style: normal;
  font-size: $mobile-font-size-h2;
  line-height: $mobile-line-height-h2;
}

@mixin font-mobile-titre-bold {
  font-family: $mobile-font-family-titre-bold;
  font-weight: $mobile-font-weight-titre-bold;
  font-style: normal;
  font-size: $mobile-font-size-titre-bold;
  letter-spacing: $mobile-letter-spacing-titre-bold;
}

@mixin font-mobile-titre-regular {
  font-family: $mobile-font-family-titre-regular;
  font-weight: $mobile-font-weight-titre-regular;
  font-style: normal;
  font-size: $mobile-font-size-titre-regular;
}

@mixin font-mobile-courant-bold {
  font-family: $mobile-font-family-courant-bold;
  font-weight: $mobile-font-weight-courant-bold;
  font-style: normal;
  font-size: $mobile-font-size-courant-bold;
  line-height: $mobile-line-height-courant-bold;
}

@mixin font-mobile-courant-regular {
  font-family: $mobile-font-family-courant-regular;
  font-weight: $mobile-font-weight-courant-regular;
  font-style: normal;
  font-size: $mobile-font-size-courant-regular;
  line-height: $mobile-line-height-courant-regular;
}

@mixin font-mobile-small-text {
  font-family: $mobile-font-family-small-text;
  font-weight: $mobile-font-weight-small-text;
  font-style: normal;
  font-size: $mobile-font-size-small-text;
  line-height: $mobile-line-height-small-text;
}

@mixin font-mobile-caption {
  font-family: $mobile-font-family-caption;
  font-weight: $mobile-font-weight-caption;
  font-style: normal;
  font-size: $mobile-font-size-caption;
  line-height: $mobile-line-height-caption;
}

@mixin font-mobile-button {
  font-family: $mobile-font-family-button;
  font-weight: $mobile-font-weight-button;
  font-style: normal;
  font-size: $mobile-font-size-button;
}

@mixin font-mobile-link {
  font-family: $mobile-font-family-link;
  font-weight: $mobile-font-weight-link;
  font-style: normal;
  font-size: $mobile-font-size-link;
}

@mixin font-mobile-timeline {
  font-family: $mobile-font-family-timeline;
  font-weight: $mobile-font-weight-timeline;
  font-style: normal;
  font-size: $mobile-font-size-timeline;
  text-transform: capitalize;
}

@mixin font-mobile-icon-legend {
  font-family: $mobile-font-family-icon-legend;
  font-weight: $mobile-font-weight-icon-legend;
  font-style: normal;
  font-size: $mobile-font-size-icon-legend;
  text-transform: capitalize;
}
