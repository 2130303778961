.step10-sketch-comments {
  .screen__content {
    .content {
      margin: $spacing-24 $spacing-12;
      .illustration__image.sketch {
        max-width: 100%;
        border: 10px solid $color-corporate-green;
        margin-block-start: 0;
      }
      .vehicle {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        h2 {
          padding-top: 0;
          padding-bottom: 0;

          img {
            max-height: 64px;
            max-width: 64px;
          }
          svg {
            margin: 9px 0;
            max-height: 42px;
          }
        }
      }
    }
  }
}
