.vehicle-shape-selector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $spacing-12;

  .vehicle-shape {
    display: flex;
    flex: 1;
    height: 135px;
    padding: $spacing-12;
    flex-direction: column;
    align-items: center;
    border-radius: $radius-4xl;
    background: #ffffff;
    color: $color-corporate-primary;
    cursor: pointer;

    > svg {
      min-height: 60px;
    }

    > p {
      @include font-mobile-icon-legend;
      text-align: center;
      margin-top: $spacing-12;
    }

    &-selected {
      background: $color-corporate-green;
      color: #ffffff;
    }
  }
}
