.photos-uploader {
  display: flex;
  flex-direction: column;
  gap: $spacing-24;

  .error {
    background: $color-complement-error;
    padding: $spacing-16;
    display: flex;
    position: relative;

    .btn {
      position: absolute;
      top: $spacing-8;
      right: $spacing-8;
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  .photos {
    display: flex;
    flex-direction: column;
    gap: $spacing-8;
    .photo {
      position: relative;
      .btn {
        position: absolute;
        top: $spacing-16;
        right: $spacing-16;
      }
    }

    &:has(.photo) {
      + form {
        > label {
          .btn {
            margin: $spacing-16 auto;
          }
        }
      }
    }
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;

    > input {
      display: none;
    }

    > label {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $radius-xl;
      border: 1px dashed $color-corporate-tertiary;

      .btn {
        border-radius: $radius-4xl;
        margin: $spacing-64 auto;
        flex-direction: column;
      }

      &.drag-active {
        background-color: $color-complement-clear-blue;
      }
    }
  }
}

#photo-drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
