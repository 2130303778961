.screen.step1-driver,
.screen.step1-insured,
.screen.step1-vehicle,
.screen.step2-driver,
.screen.step2-insured,
.screen.step2-vehicle {
  .screen__content {
    .content {
      gap: $spacing-24;
      .vehicle {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        h2 {
          padding-top: 0;
          padding-bottom: 0;

          img {
            max-height: 64px;
            max-width: 64px;
          }
          svg {
            margin: 9px 0;
            max-height: 42px;
          }
        }
      }

      .question {
        margin: 0 auto;
      }
    }
  }
}
.screen.step3-summary {
  .vehicle {
    h2 {
      @include font-mobile-courant-bold;
    }
  }
}
