.screen.signature {
  .screen__content {
    .content {
      .signature-info {
        display: flex;
        padding: $spacing-12 $spacing-18;
        align-items: center;
        gap: $spacing-12;
        align-self: stretch;
        border-radius: $radius-lg;
        background: $color-complement-clear-blue;
        color: $color-corporate-primary;

        &.vehicle-b {
          background: $color-corporate-secondary;
          color: $color-corporate-tertiary;
        }

        .svg-container {
          padding: $spacing-10;
          width: 40px;
          height: 40px;
          border-radius: $radius-full;
          background: $color-corporate-primary;
          svg {
            color: #ffffff;
          }
        }

        .illustration__image {
          max-width: 52px;
          max-height: 52px;
        }
      }

      .vehicle-b-precision {
        @include font-mobile-courant-regular;
        font-style: italic;
        text-align: center;
      }
    }
  }
}
