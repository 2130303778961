.screen.greetings {
  .screen__content {
    .content {
      gap: $spacing-32;

      .illustration__image {
        margin-block: 0;
      }

      .greetings-text {
        @include font-mobile-titre-bold;
        color: $color-corporate-primary;
        text-align: center;

        strong {
          color: $color-corporate-secondary;
        }
      }

      .btn {
        margin-bottom: $spacing-16;
      }
    }
  }
}
