.chip {
  padding: $spacing-8 $spacing-16;
  border-radius: $radius-full;
  gap: $spacing-10;
  width: max-content;
  display: flex;
  align-items: center;
  font-size: $mobile-font-size-small-text;

  &:has(&__check) {
    padding-left: $spacing-8;
  }

  &__check {
    display: flex;
    align-items: center;
    padding: $spacing-2;
    border-radius: $radius-full;
  }

  $variants: (
    primary: (
      'background-color': $color-corporate-primary,
      'color': white,
      'check': (
        'background-color': $color-complement-clear-blue,
        'color': $color-corporate-primary,
      ),
    ),
    secondary: (
      'background-color': $color-complement-clear-blue,
      'color': $color-corporate-tertiary,
      'check': (
        'background-color': $color-corporate-primary,
        'color': white,
      ),
    ),
    tertiary: (
      'background-color': $color-corporate-tertiary,
      'color': white,
      'check': (
        'background-color': $color-corporate-tertiary,
        'color': white,
      ),
    ),
  );

  @each $variant, $properties in $variants {
    &--#{$variant} {
      background-color: map-get($properties, 'background-color');
      color: map-get($properties, 'color');

      .chip__check {
        background-color: map-get(
          map-get($properties, 'check'),
          'background-color'
        );
        color: map-get(map-get($properties, 'check'), 'color');
      }
    }
  }
}
