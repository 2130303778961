.wounded {
  position: relative;
  margin-top: $spacing-20;
  border-radius: $radius-xl;
  background-color: $color-complement-clear-blue;
  padding: $spacing-24;
  display: flex;
  align-items: center;
  min-height: 146px;

  .btn {
    z-index: 1;
  }

  &[data-status='add'] {
    .btn {
      border-radius: $radius-4xl;
      height: 105px;
      width: 105px;
      padding: 0;
      flex-direction: column;

      svg {
        width: 50px;
        height: 50px;
      }
    }
  }

  &[data-status='editable'] {
    justify-content: space-between;
    background-color: $color-complement-blue;
    box-shadow: 5px 20px 35px 0 rgba($color-complement-blue, 0.5);

    .btn {
      border-radius: $radius-full;
      min-height: 54px;
      min-width: 54px;
      padding: 0;
      flex-direction: column;
    }
  }

  &__name {
    color: white;
    @include font-desktop-titre-bold;
    font-size: $mobile-font-size-titre-bold;
    z-index: 1;
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: 25px;
    user-select: none;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: $spacing-16;
  }
}
