.btn__group {
  display: flex;
  gap: $spacing-12;
  flex-wrap: wrap;
  width: 100%;

  .btn {
    flex: 1 1 0;
    white-space: nowrap;
    box-shadow:
      0 20px 26px 3px rgba(14, 14, 44, 0.1),
      0 1px 1px 0 rgba(8, 12, 24, 0.05);
  }
}

.btn {
  all: unset;
  cursor: pointer;
  border-radius: $radius-full;
  transition:
    background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  @include font-mobile-button;
  //letter-spacing: 4%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
    background-color: #d3d9df;
    color: $color-complement-subtil;

    &.btn--outline {
      border-color: #d3d9df;
      color: $color-complement-subtil;
    }
  }

  &.btn--icon {
    border-radius: $radius-full;
    min-height: 54px;
    min-width: 54px;
    padding: 0;
    flex-direction: column;
  }

  $sizes: (
    sm: $spacing-10 $spacing-16,
    md: $spacing-12 $spacing-20,
    lg: $spacing-16 $spacing-24,
  );

  @each $size, $value in $sizes {
    &--#{$size} {
      padding: $value;
    }
  }

  $variants: (
    primary: (
      'background-color': $color-corporate-primary,
      'color': white,
      'border': none,
      'outline': (
        'border': 1px solid $color-corporate-primary,
        'color': $color-corporate-primary,
      ),
    ),
    secondary: (
      'background-color': $color-corporate-secondary,
      'color': white,
      'border': none,
      'outline': (
        'border': 1px solid $color-corporate-secondary,
        'color': $color-corporate-secondary,
      ),
    ),
    tertiary: (
      'background-color': $color-corporate-tertiary,
      'color': white,
      'border': none,
      'outline': (
        'border': 1px solid $color-corporate-tertiary,
        'color': $color-corporate-tertiary,
      ),
    ),
    green: (
      'background-color': $color-corporate-green,
      'color': white,
      'border': none,
      'outline': (
        'border': 1px solid $color-corporate-green,
        'color': $color-corporate-tertiary,
      ),
    ),
  );

  @each $variant, $properties in $variants {
    &--#{$variant} {
      background-color: map-get($properties, 'background-color');
      color: map-get($properties, 'color');
      border: map-get($properties, 'border');

      &:not(:disabled):hover {
        background-color: darken(map-get($properties, 'background-color'), 10%);
      }

      &.btn--outline {
        background-color: transparent;
        border: map-get(map-get($properties, 'outline'), 'border');
        color: map-get(map-get($properties, 'outline'), 'color');

        &:not(:disabled):hover {
          background-color: map-get($properties, 'background-color');
          color: map-get($properties, 'color');
        }
      }
    }
  }
}
