@import 'base/reset';

@import 'utilities/variables';
@import 'utilities/mixins';
@import 'utilities/typography';

@import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
@import 'react-pdf/dist/cjs/Page/TextLayer.css';

@import 'components/button';
@import 'components/chip';
@import 'components/form';
@import 'components/illustration';
@import 'components/input';
@import 'components/list';
@import 'components/notification';
@import 'components/photos-uploader';
@import 'components/scrollbar';
@import 'components/timeline-step';
@import 'components/vehicle';
@import 'components/vehicle-damage-selector';
@import 'components/vehicle-shape-selector';
@import 'components/witness';
@import 'components/wounded';

@import 'partials/actions';
@import 'partials/content';
@import 'partials/footer';
@import 'partials/header';
@import 'partials/screen';

@import 'screens/start';
@import 'screens/checkboxes';
@import 'screens/witnesses';
@import 'screens/wounded';
@import 'screens/steps-driver-insured-vehicle';
@import 'screens/step-photos';
@import 'screens/steps-damages';
@import 'screens/step-chatbot';
@import 'screens/step-sketch-comments';
@import 'screens/step-localization';
@import 'screens/step-preview';
@import 'screens/signature';
@import 'screens/greetings';

.app {
  position: relative;
  background-color: $color-complement-clear-blue;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas:
    'header'
    'content';
  max-width: 375px;
  min-height: 100dvh;
  max-height: 100dvh;
  overflow: auto;
  margin: 0 auto;
}
