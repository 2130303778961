.screen.checkboxes {
  .screen__content {
    .content {
      .input {
        .input__checkbox {
          .input__label {
            span {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
