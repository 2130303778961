::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: rgba($color-corporate-tertiary, 0.05);
  border-radius: $radius-full;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba($color-corporate-tertiary, 0.1);
}
